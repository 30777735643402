import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  Label,
} from "recharts";
import * as d3 from "d3";
import { connectProviderContract } from "../ethers/connectProviderContract";
import { AccessControlledAggregator as ABI } from "../ethers/config";

const Chart = (ComposedChartProps = {}) => {
  const {
    description = "Pair",
    data,
    margin,
    // selectedTime,
  } = ComposedChartProps;

  // build a list of each day from firstDate to lastDate. Returns array of strings
  const buildDays = (begin, end) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(begin);
    const secondDate = new Date(end);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    let days = [];
    for (let i = 0; i <= diffDays; i++) {
      days.push(
        new Date(firstDate.getTime() + i * oneDay).toLocaleDateString()
      );
    }
    return days;
  };

  const formatData = (data) => {
    let formatData = [];
    const initialStart = 1596884400;
    // console.log("data TEST: ", data);
    data.forEach(async (d, i) => {
      // if d.rounds===0, then skip this phase
      if (d.rounds === 0) {
        return;
      }
      // check if firstRound and lastRound have answer data. If not, call addMissingRoundData()
      if (d.firstRound.answer === 0) {
        // d.firstRound = await addMissingRoundData(d.aggregator, 1);
        // if the firstRound is still 0, then skip this phase
        if (d.firstRound.answer === 0 || d.firstRound === undefined) {
          return;
        }
      }
      if (d.lastRound.answer === 0) {
        d.lastRound = await addMissingRoundData(d.aggregator, d.rounds);
        // console.log("d.lastRound: ", d.lastRound);
        // if the lastRound is still 0, then skip this phase
        if (d.lastRound.answer === 0 || d.lastRound === undefined) {
          return;
        }
      }

      formatData.push({
        phaseId: `${d.phaseId}`,
        start:
          d.firstRound.updatedAt > initialStart
            ? new Date(d.firstRound.updatedAt * 1000).toLocaleDateString()
            : initialStart, // only include day, month, year
        end:
          d.lastRound.updatedAt > initialStart
            ? new Date(d.lastRound.updatedAt * 1000).toLocaleDateString()
            : initialStart,
        days: Math.round(
          (d.lastRound.updatedAt - d.firstRound.updatedAt) / (60 * 60 * 24) + 1
        ),
      });
    });
    // console.log("formatdata: ", formatData);
    // add phaseNames to formatData
    const phaseNames = formatData.map((phase) => `phase${phase.phaseId}`);
    formatData.push({ active: phaseNames });

    return formatData;
  };

  // Takes in aggregator contract and roundId. Connects to Ethers and calls getRoundData(). Returns round data
  const addMissingRoundData = async (aggregatorId, roundId) => {
    const aggregator = await connectProviderContract(aggregatorId, "ETH", ABI);
    // console.log("aggregator connected. looking for round:  ", roundId);
    try {
      const roundData = await aggregator.getRoundData(roundId);
      return {
        aggregatorId: aggregatorId,
        roundId: roundId,
        answer: roundData.answer.toString(),
        startedAt: roundData.startedAt.toString(),
        updatedAt: roundData.updatedAt.toString(),
        answeredInRound: roundData.answeredInRound.toString(),
      };
    } catch (err) {
      console.log("error: ", err);
    }
  };

  // loop through each date in dateRange and check if it is in the range of each phase. Returns an array of objects in format { date: "2021-11-01", phase1: 1, phase2: 0, phase3:0 }
  const buildPhaseIdRange = (dateRange, formattedData) => {
    let phaseIds = [];
    /// build array of phaseIds by finding active array in formattedData
    const phaseNames = formattedData.filter((phase) => phase.active);
    // then remove active array from formattedData so it doesn't get looped through
    formattedData.pop();
    // build array of all phaseIds
    let allPhaseIds = phaseNames[0].active;
    // allPhaseIds = allPhaseIds.map((phase) => {
    //   return `phase${phase.phaseId}`;
    // });
    // console.log("allPhaseIds: ", allPhaseIds);
    // add each phaseId to phaseIds array with a range of dates with computed property names ex { date: "2021-11-01", phase1: 0, phase2: 0, phase3:0 } set values equal to 0

    dateRange.forEach((date) => {
      phaseIds.push({ date: date });
    });

    // loop through each phaseId and check if it is in the range of each phase. If it is, set the value to the value of the phaseId, otherwise set it to 0
    phaseIds.forEach((phaseId) => {
      allPhaseIds.forEach((phase) => {
        phaseId[phase] = 0;
      });
      formattedData.forEach((phase) => {
        if (
          new Date(phaseId.date).getTime() >= new Date(phase.start).getTime() &&
          new Date(phaseId.date).getTime() <= new Date(phase.end).getTime()
        ) {
          phaseId[`phase${phase.phaseId}`] = phase.phaseId;
        } else {
          phaseId[`phase${phase.phaseId}`] = null;
        }
      });
    });

    // console.log("phaseIds: ", phaseIds); // verbose output

    return phaseIds;
  };

  // buildLines function takes in an array of objects in format { date: "2021-11-01", phase1: 1, phase2: 0, phase3:0 } and generates a  recharts line for each active phase listed in the active key
  const buildLines = (phases) => {
    const colors = [
      "#8884d8",
      "#82ca9d",
      "#ffc658",
      "#ff0000",
      "#0000ff",
      "#00ff00",
      "#ff00ff",
      "#00ffff",
      "#ffff00",
    ];
    let lines = [];
    for (let i = 0; i < phases.length; i++) {
      if (phases[i]) {
        lines.push(
          <Line
            key={i}
            dataKey={phases[i]}
            type="stepAfter"
            stroke={colors[i]}
            dot={false}
            strokeWidth={10}
          />
        );
      }
    }
    return lines;
  };

  const formattedData = formatData(data);
  // console.log("data: ", data);
  // console.log("formattedData", formattedData);

  let [xMin] = d3.extent(data, (d) => d.firstRound.updatedAt * 1000);
  if (xMin === 0 || xMin === undefined) {
    xMin = 1596884400 * 1000;
  }
  xMin = new Date(xMin).toLocaleDateString();
  let [, xMax] = d3.extent(data, (d) => d.lastRound.updatedAt);
  xMax = new Date(xMax * 1000).toLocaleDateString();
  let [yMin] = d3.extent(data, (d) => d.phaseId - 1);
  let [, yMax] = d3.extent(data, (d) => d.phaseId);

  const xDomain = buildDays(xMin, xMax);
  console.log("data output:  ", formattedData);
  const phaseIdRange = buildPhaseIdRange(xDomain, formattedData);
  const phaseNames = formattedData.map((phase) => `phase${phase.phaseId}`);
  const buildTicks = (yMin, yMax) => {
    let ticks = [];
    for (let i = yMin; i <= yMax + 1; i++) {
      ticks.push(i);
    }
    return ticks;
  };

  return (
    <>
      <ResponsiveContainer
        minHeight={500}
        minWidth={350}
        // maxWidth={1000}
        // maxHeight={100}
        height="100%"
        width="99%"
        margin={margin}
      >
        <ComposedChart data={phaseIdRange}>
          <CartesianGrid />
          <XAxis
            dataKey="date"
            // domain={[xMin, xMax]}
            type="category"
            scale="auto"
          >
            <Label value={description} offset={-4} position="insideBottom" />
          </XAxis>
          <YAxis
            dataKey="active"
            domain={[yMin, +yMax + 1]}
            ticks={buildTicks(yMin, yMax)}
            interval="preserveStart"
            tickFormatter={(tick) => {
              if (tick === 0) {
                return ` `;
              }
              return `Phase ${tick}`;
            }}
          />
          <Tooltip
          // formatter={(value, name, props) => {
          //   console.log(`value: ${value}, name: ${name}, props: ${props}`);
          //   if (name === "Phase" || name === "Phases") {
          //     if (value === 0) {
          //       return;
          //     }
          //     return value;
          //   }
          // }}
          />
          <Legend />

          {/* background color of site: #E9F5F1 */}
          {buildLines(phaseNames)}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export { Chart };
