import { useEffect, useState } from "react";
import { Selector } from "../lookup/selectorNetwork";
import { Eye, Link, LoadingWheel } from "../Icons";
import { truncateEthAddress } from "../ethers/truncateEthAddress";
import { SubSelector } from "../lookup/selectorSubNetwork";
import { OracleSelector } from "../lookup/selectorOracle";
import { evaluateOracle } from "../lookup/evaluateOracle";
import { Chart } from "../lookup/Chart";

const Lookup = ({ cache }) => {
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedSubNetwork, setSelectedSubNetwork] = useState("mainnet");
  const [selectedOracle, setSelectedOracle] = useState(null);
  const [selectedOracleMetadata, setSelectedOracleMetadata] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [subNetworks, setSubNetworks] = useState(null);
  const [oracleList, setOracleList] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [chainSelector, setChainSelector] = useState(null);
  const [key, setKey] = useState(0);

  //SAMPLE DATA
  // const oracleArraySample =
  //   //   {
  //   //     aggregator: "0xa7D38FBD325a6467894A13EeFD977aFE558bC1f0",
  //   //     phaseId: 1,
  //   //     rounds: 0,
  //   //   },
  //   //   {
  //   //     aggregator: "0xcd0465b4ba06f3193ADd14272af187aB414534ff",
  //   //     phaseId: 2,
  //   //     rounds: 260,
  //   //   },
  //   //   {
  //   //     aggregator: "0xFe16F630Eb0Ca70661B071360701abf980126d3e",
  //   //     phaseId: 3,
  //   //     rounds: 439,
  //   //   },
  //   //   {
  //   //     aggregator: "0x4588EC4ddcF1D8dbcb5a1273d22F8485885C45A4",
  //   //     phaseId: 4,
  //   //     rounds: 93,
  //   //   },
  //   //   {
  //   //     aggregator: "0xDEc0a100eaD1fAa37407f0Edc76033426CF90b82",
  //   //     phaseId: 5,
  //   //     rounds: 14521,
  //   //   },
  //   // ];
  //   [
  //     {
  //       aggregator: "0xc697FCA98f961169B53e80b718155b55fc1a17d5",
  //       phaseId: 1,
  //       rounds: 3541,
  //       firstRound: {
  //         0: "1",
  //         1: "12119000000",
  //         2: "1617004929",
  //         3: "1617004929",
  //         4: "1",
  //         roundId: "1",
  //         answer: "12119000000",
  //         startedAt: "1617004929",
  //         updatedAt: "1617004929",
  //         answeredInRound: "1",
  //       },
  //       lastRound: {
  //         0: "3539",
  //         1: "12697000000",
  //         2: "1672252787",
  //         3: "1672252787",
  //         4: "3539",
  //         roundId: "3539",
  //         answer: "12697000000",
  //         startedAt: "1672252787",
  //         updatedAt: "1672252787",
  //         answeredInRound: "3539",
  //       },
  //     },
  //   ];

  // Builds the selector for the network (Ethereum, Solana, etc.)
  const selectorChange = (f) => {
    setSubNetworks(null);
    setSelectedNetwork(f.value);
    setSelectedSubNetwork("mainnet");
    buildOracleSelector("mainnet");
    setSelectedOracle(null);
    setOracleList(null);
    setChartData(null);
    setSelectedOracleMetadata(null);

    setKey((prev) => prev + 1);
    getNetworks(f.value);
  };

  // Handles change of the subnetwork selector (Ethereum Mainnet, Ethereum Goerli, etc.)
  const blockChainChange = (f) => {
    setSelectedSubNetwork(f.value);
    buildOracleSelector(f.value);
    getNetworks(selectedNetwork);
    setSelectedOracle(null);
    setChartData(null);
    setSelectedOracleMetadata(null);

    setKey((prev) => prev + 1);
  };

  // Handles the change of the oracle selector
  const oracleChange = (f) => {
    setSelectedOracle(f.value);
    setChartData(null);
    setKey((prev) => prev + 1);
    setSelectedOracleMetadata(null);
  };

  // Gets the list of subnetworks for the selected network (Ethereum Goerli and Mainnet)
  const getNetworks = (selected) => {
    const temp = [];
    Object.values(cache).forEach((chain) => {
      if (chain.page === selected) {
        Object.values(chain.networks).forEach((nw) => {
          const tempObj = {
            value: nw.networkType,
            label: nw.name,
          };
          temp.push(tempObj);
        });
      }
    });
    setSubNetworks(temp.sort((a, b) => (a.label > b.label ? 1 : -1)));
  };

  // constrcuts the explorer url for the selected oracle (Ethereum Mainnet ETH-USD, BTC-USD, etc.)
  const getExplorerUrl = (network) => {
    let url = "";
    Object.values(cache).forEach((chain) => {
      if (chain.page === selectedNetwork) {
        Object.values(chain.networks).forEach((nw) => {
          if (nw.networkType === network) {
            url = nw.explorerUrl;
            url = url.replace("%s", "");
          }
        });
      }
    });
    return url;
  };

  // Builds the list of oracles for the selected network (Oracles on Ethereum Mainnet, Binance Smart Chain, etc.)
  const buildOracleSelector = (network) => {
    const temp = [];
    Object.values(cache).forEach((chain) => {
      if (chain.page === selectedNetwork) {
        Object.values(chain.networks).forEach((nw) => {
          if (nw.networkType === network) {
            Object.values(nw.metadata).forEach((meta) => {
              const tempObj = {
                value: meta.path,
                label: meta.name,
              };
              temp.push(tempObj);
            });
          }
        });
      }
    });

    setOracleList(temp.sort((a, b) => (a.label > b.label ? 1 : -1)));
  };

  // Builds the metadata for the selected oracle
  const buildMetadata = () => {
    Object.values(cache).forEach((chain) => {
      if (chain.page === selectedNetwork) {
        Object.values(chain.networks).forEach((nw) => {
          if (nw.networkType === selectedSubNetwork) {
            Object.values(nw.metadata).forEach((meta) => {
              if (meta.path === selectedOracle) {
                setSelectedOracleMetadata(meta);

                setChartDataLoading(true);
                console.log("Chart Data Set");
                // console.log(
                //   "NOTE: Uncomment the line in buildMetadata() to enable the evaluation of the oracle. Sample Result: "
                // );
                // console.log([
                //   {
                //     aggregator: "0xa7D38FBD325a6467894A13EeFD977aFE558bC1f0",
                //     phaseId: 1,
                //     rounds: 0,
                //   },
                //   {
                //     aggregator: "0xcd0465b4ba06f3193ADd14272af187aB414534ff",
                //     phaseId: 2,
                //     rounds: 260,
                //   },
                //   {
                //     aggregator: "0xFe16F630Eb0Ca70661B071360701abf980126d3e",
                //     phaseId: 3,
                //     rounds: 439,
                //   },
                //   {
                //     aggregator: "0x4588EC4ddcF1D8dbcb5a1273d22F8485885C45A4",
                //     phaseId: 4,
                //     rounds: 93,
                //   },
                //   {
                //     aggregator: "0xDEc0a100eaD1fAa37407f0Edc76033426CF90b82",
                //     phaseId: 5,
                //     rounds: 14521,
                //   },
                // ]);
                return;
              }
            });
          }
        });
      }
    });
  };

  // Build the selector for the networks (Ethereum, BSC, etc)
  const buildNetworkSelector = (cache) => {
    const temp = [];
    Object.values(cache).forEach((chain) => {
      const tempObj = {
        value: chain.page,
        label: chain.label,
      };
      temp.push(tempObj);
    });
    setChainSelector(temp.sort((a, b) => (a.label > b.label ? 1 : -1)));
  };

  // Creates JSX for the metadata display of a specific oracle (Ethereum Mainnet ETH-USD, BTC-USD, etc.)
  const buildMetadataDisplay = () => {
    if (selectedOracleMetadata) {
      return (
        <>
          {/* Pair Description */}
          <div className="flex-grow pl-4">
            <h2 className=" font-semibold text-xl title-font text-gray-900">
              Update Parameters
            </h2>
            <p className="leading-relaxed text-base">
              Name : {selectedOracleMetadata.name}
            </p>
            <p className="leading-relaxed text-base">
              Contract Address:{" "}
              {truncateEthAddress(selectedOracleMetadata.contractAddress)}
            </p>
            <p className="leading-relaxed text-base">
              Explorer:{" "}
              <a
                href={`${getExplorerUrl(selectedSubNetwork)}${
                  selectedOracleMetadata.proxyAddress
                }`}
                target="_blank"
                rel="noreferrer"
              >
                {truncateEthAddress(selectedOracleMetadata.proxyAddress)}
              </a>
            </p>
            <p className="leading-relaxed text-base">
              Deviation Threshold: {selectedOracleMetadata.threshold}%
            </p>
            <p className="leading-relaxed text-base">
              HeartBeat: {selectedOracleMetadata.heartbeat} sec
            </p>
            <p className="leading-relaxed text-base">
              Contract Version: {selectedOracleMetadata.contractVersion}
            </p>
          </div>
        </>
      );
    }
  };

  const buildOracleOverview = () => {
    if (!chartDataLoading && chartData.length > 0) {
      return (
        <>
          <div className="flex-grow pl-4">
            <h2 className=" font-semibold text-xl title-font text-gray-900">
              Oracle Aggregators
            </h2>
            {/* map of the oracleArraySample */}
            {chartData.map((oracle) => (
              <div key={oracle.aggregator}>
                <p className="leading-relaxed text-base">
                  <a
                    href={`${getExplorerUrl(selectedSubNetwork)}${
                      oracle.aggregator
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Phase {oracle.phaseId}:
                    {truncateEthAddress(oracle.aggregator)}
                  </a>
                  ({oracle.rounds} rounds)
                </p>
                {/* start and end time converted from unix time */}
                <p className="leading-relaxed text-base">
                  Start Time:
                  {oracle.rounds === 0
                    ? "Not Applicable - Contract Destroyed"
                    : oracle.firstRound
                    ? new Date(
                        oracle.firstRound.updatedAt * 1000
                      ).toLocaleString()
                    : "Not Applicable - Contract Destroyed"}
                </p>
                <p className="leading-relaxed text-base">
                  End Time:
                  {oracle.rounds === 0
                    ? null
                    : oracle.lastRound
                    ? new Date(
                        oracle.lastRound.updatedAt * 1000
                      ).toLocaleString()
                    : "Not Applicable - Contract Destroyed"}
                </p>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  // UseEffect with settimeout to delay the loading of cache information to allow it to be loaded
  useEffect(() => {
    const timeout = setTimeout(() => {
      buildNetworkSelector(cache);
      setIsPending(false);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [cache]);

  // useEffect(() => {
  //   (async () => {
  //     buildNetworkSelector(cache);
  //     setIsPending(false);
  //   })();
  // }, [cache]);

  // useEffect to update the selected oracle metadata when the selected oracle metadata loads
  // (Builds chart data for the selected oracle after Chartdata is set)
  useEffect(() => {
    if (selectedOracleMetadata) {
      (async () => {
        setChartDataLoading(true);
        setChartData(null);
        try {
          const res = await evaluateOracle(selectedOracleMetadata.proxyAddress);
          setChartData(res);
        } catch (err) {
          console.log(err);
        } finally {
          setChartDataLoading(false);
        }
      })();
    } else {
      setChartData(null);
    }
  }, [selectedOracleMetadata]);
  return (
    <section className="text-black py-20 body-font w-full bg-background-light flex grow items-center justify-center  ">
      {/* Translucent box thing with rounded corners */}
      <div className="container pt-28 flex rounded-xl border border-foreground-alt-500 shadow pb-4 sm:p-8 px-2  mx-1 flex-wrap h-full w-full justify-center align-middle items-center ">
        {/* Div for Pair Name, Description, and Information */}
        <div className="lg:w-1/3 md:w-1/3 md:px-4 md:py-6 ">
          {/* Pair Name and Loading Status */}
          <div className="flex relative pb-12">
            <h1 className="pl-4 font-semibold text-5xl title-font text-gray-900 flex items-center justify-center">
              Lookup Oracle
            </h1>
          </div>

          {/* Div for the Network Selector */}
          {chainSelector ? (
            <div className="flex relative pb-12">
              <div className="w-10 absolute inset-0 flex items-center justify-center">
                <div className="w-1 h-full bg-gray-800 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-emerald-600 inline-flex items-center justify-center text-black relative z-10">
                <Eye />
              </div>
              {buildMetadataDisplay()}
            </div>
          ) : null}

          {/* Div for the Oracle Overview */}
          {selectedOracleMetadata ? (
            <div className="flex relative pb-12 ">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                {/* <div className="h-full w-1 bg-gray-800 pointer-events-none"></div> */}
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-emerald-600 inline-flex items-center justify-center text-black relative z-10">
                <Link />
              </div>
              {chartDataLoading ? (
                <>
                  {" "}
                  <p>Loading...</p>{" "}
                </>
              ) : (
                buildOracleOverview()
              )}
            </div>
          ) : null}
        </div>
        <div className="lg:w-2/3 md:w-5/6 md:pr-2 md:py-2 rounded-lg md:px-4 flex-col md:mt-0 mt-12 w-full inline-flex items-center justify-center">
          {isPending ? (
            <div>
              <p>Data is loading ... </p>
              <br />
              <LoadingWheel />
            </div>
          ) : (
            <>
              <div className="flex justify-between items-end">
                {chainSelector != null && (
                  <Selector
                    selectorChange={selectorChange}
                    data={chainSelector}
                    label={"Select a Blockchain"}
                  />
                )}
                {subNetworks != null && (
                  <SubSelector
                    blockChainChange={blockChainChange}
                    data={subNetworks}
                    label={"Select a Sub-Network"}
                    key={selectedNetwork}
                  />
                )}
                {oracleList != null && (
                  <OracleSelector
                    OracleChange={oracleChange}
                    data={oracleList}
                    label={"Select a Sub-Network"}
                    key={oracleList}
                  />
                )}
                {selectedNetwork && selectedSubNetwork && selectedOracle && (
                  <button
                    className="flex-shrink-0 bg-emerald-600 border-0 ml-4 py-2 mb-1 px-8 focus:outline-none hover:bg-emerald-700 rounded text-lg text-white"
                    onClick={buildMetadata}
                  >
                    Lookup
                  </button>
                )}
              </div>

              {chartData && (
                <Chart
                  data={chartData}
                  margin={{ top: 10, right: 10, left: 5, bottom: 10 }}
                  key={key}
                  description={selectedOracleMetadata.name}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Lookup;
