import { useState } from "react";
import {
  query,
  orderByChild,
  get,
  endAt,
  limitToLast,
} from "firebase/database";
import { useEffect } from "react";

const SimpleMovingAverage = ({ range, dateRef, decimals = 8 }) => {
  const [answerGiven, setAnswerGiven] = useState(false);
  const [dateArray, setDateArray] = useState([]);
  const [sma, setSma] = useState(0);
  const [result, setResult] = useState(null);

  // e is days. build array of dates from today to today - e. then get data for each date. then average the data. then display the average.
  const handleDateChange = (e) => {
    const days = e.target.value;
    setSma(days);

    let date = new Date();
    date = date.setUTCHours(0, 0, 1, 0) / 1000;
    const resultArray = [];
    for (let i = 0; i < days; i++) {
      resultArray.push(date);
      date = date - 86400;
    }
    setDateArray(resultArray);
  };

  const getAnswers = async (dateArray) => {
    return Promise.all(
      dateArray.map(async (date) => {
        const q = query(
          dateRef,
          orderByChild("startedAt"),
          endAt(date),
          limitToLast(1)
        );
        const snapshot = await get(q);
        if (snapshot.exists()) {
          const answer = Object.values(snapshot.val())[0].answer;
          const roundId = Object.values(snapshot.val())[0].roundId;
          return { roundId: roundId, answer: answer };
        } else {
          return null;
        }
      })
    );
  };

  const codeBlock = (data, smaAnswer, smaDays) => {
    const roundList = data.map((round) => {
      return (
        <li key={round.roundId}>
          RoundId: {round.roundId} - Answer {round.answer}
        </li>
      );
    });

    try {
      return (
        <div className="flex flex-auto p-2 text-gray-300 bg-gray-800 rounded-lg mb-8 mt-2 md:overflow-y-scroll overflow-auto text-xs max-w-sm sm:max-w-lg md:max-w-full  sm:text-lg lg:w-full max-h-[500px] ">
          <pre className="flex flex-col">
            {smaDays} Day Simple Moving Average:
            <code className="font-bold"> {smaAnswer}</code>
            <code className="font-bold">
              (or ${(smaAnswer / 100000000).toFixed(2)})
            </code>
            <br /> <br />
            data:<code className="flex flex-col ">{roundList}</code>
          </pre>
        </div>
      );
    } catch (e) {
      return (
        <div className="flex flex-auto p-2 text-gray-300 bg-gray-800 rounded-lg mb-8 mt-2 overflow-auto text-xs max-w-sm sm:max-w-lg md:max-w-full  sm:text-lg lg:w-full ">
          <pre className="flex flex-col ">
            <code className="flex ">No answer found</code>
            <code className="flex ">data: {JSON.stringify(data)}</code>
          </pre>
        </div>
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Look up the date in RTDB. Find the closest value to the date.

    const answers = await getAnswers(dateArray);
    const smaAnswers = answers.map((answer) => answer.answer);
    const average = (array) => array.reduce((a, b) => a + b) / array.length;
    const smaAnswer = average(smaAnswers).toFixed();

    console.log(smaAnswer); // good

    setResult(codeBlock(answers, smaAnswer, sma));
    setAnswerGiven(true);
  };

  const resetAll = () => {
    setAnswerGiven(false);
    setSma(0);
    setResult(null);
  };

  useEffect(() => {
    setAnswerGiven(false);
  }, [range]);

  return (
    <>
      {answerGiven ? (
        <>
          <div className="flex flex-col justify-center align-middle">
            <div className="flex flex-col justify-center align-middle">
              <div className="text-center my-8">{result}</div>
            </div>
            <button
              className="flex mx-auto text-white bg-emerald-800 border-0 py-2 px-8 focus:outline-none hover:bg-emerald-600 rounded text-lg  mt-2"
              onClick={() => resetAll()}
            >
              Back
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center my-8">
          <h1 className="text-2xl font-bold text-gray-600">
            Enter number of days to calculate SMA.
          </h1>

          <input
            type="range"
            onChange={handleDateChange}
            required
            min={2}
            max={100}
            className="border-2 border-gray-600 rounded-md p-2 my-2"
          />

          {sma ? (
            <button
              onClick={handleSubmit}
              className="flex mx-auto text-white bg-emerald-800 border-0 py-2 px-8 focus:outline-none hover:bg-emerald-600 rounded text-lg  mt-2"
            >
              Submit {sma} SMA
            </button>
          ) : (
            <button
              disabled={true}
              className="flex mx-auto text-slate-600 border-slate-600 py-2 px-8 bg-stone-400  hover:bg-stone-700 hover:text-white rounded text-lg  mt-2"
            >
              Awaiting input
            </button>
          )}
        </div>
      )}
    </>
  );
};

export { SimpleMovingAverage };
