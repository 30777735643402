/* eslint-disable no-redeclare */
import {
  aggregatorContractABI as accessControlledAggregatorABI,
  EACAggregatorProxyABI,
  aggregatorABI,
  aggregatorFacadeABI,
  accessControlledOffchainAggregatorABI,
} from "../ethers/config";
import Web3 from "web3";
import BigNumber from "bignumber.js";
// import { connectProviderContract } from "../ethers/connectProviderContract";

const evaluateOracle = async (aggregatorProxyAddress) => {
  //   /* Param expected in the request:
  // proxyAddress: This is the address of the proxy contract for data feed that has all the historical data that we want to access. ie, taken from https://docs.chain.link/docs/reference-contracts/
  // */

  //constant variables
  const MAINNET_PRICE_FEEDS_LIVE_BLOCK_NO = 10606501; //mainnet block that price feeds went live on Ethereum, Aug 6 2020
  const MAINNET_LOG_CHUNKS = 200000;
  const NEW_ROUND_TOPIC =
    "0x0109fc6f55cf40689f02fbaad7af7fe7bbac8a3d2186600afc7d3e10cac60271"; //topic0 on NewRound event emitted.
  const NEW_ROUND_TOPIC_LEGACY_AGGREGATOR =
    "0xc3c45d1924f55369653f407ee9f095309d1e687b2c0011b1f709042d4f457e17"; //older aggregators had a different topic
  // const RPC_URL = process.env.REACT_APP_RPC_URL; //connection to Ethereum
  let RPC_URL;
  const envTest = process.env.REACT_APP_ENV_TEST;

  //get a connection to we3, with a slightly longer timeout (for searching logs using infura). If it is localhost, use the RPC_URL from .env, otherwise use infura scoped to site
  if (window.location.hostname === "localhost") {
    RPC_URL = process.env.REACT_APP_RPC_URL;
  } else {
    console.log("not localhost");
    RPC_URL = "https://mainnet.infura.io/v3/fd4bd6b4aeaa4a01888a0deaca1648b6"; // https://mainnet.infura.io/v3/fd4bd6b4aeaa4a01888a0deaca1648b6
  }
  const web3 = new Web3(
    new Web3.providers.HttpProvider(RPC_URL, { timeout: 10e4 })
  );

  console.log("envTest: ", envTest);
  console.log("RPC Url is valid? ", RPC_URL === undefined ? "No" : "Yes");

  try {
    //validate inputs here
    //check passed in proxy contract is a valid ethereum contract address
    let validAddress = web3.utils.isAddress(aggregatorProxyAddress);
    if (!validAddress) {
      throw new Error("Invalid proxy address");
    }

    console.log(
      new Date().toISOString() +
        ": New historical price request received for proxy: " +
        aggregatorProxyAddress
    );

    //now that we've validated the address, lets connect to the proxy contract, and grab a list of all the underlying aggregator contracts with their phase IDs
    //we need this info to work out which aggregator contract we need to look at to find the historical price data
    var aggregators = [];
    const proxyAggregator = new web3.eth.Contract(
      EACAggregatorProxyABI,
      aggregatorProxyAddress
    );

    let rounds = [];
    let finalArray = [];
    let phaseIds = [];
    let currentBlock = await web3.eth.getBlockNumber();
    let phaseId = 1;
    let aggregatorContract = 1;
    while (true) {
      aggregatorContract = await proxyAggregator.methods
        .phaseAggregators(phaseId)
        .call();
      // eslint-disable-next-line eqeqeq
      if (!(parseInt(aggregatorContract) == "0")) {
        aggregators.push(aggregatorContract);
        phaseIds.push(phaseId);
        phaseId = phaseId + 1;
      } else {
        break;
      }
    }

    //now we have all the aggregators and their phases (index in the array + 1, as phases start from 1), assuming there's more than 1, we need to work out which one contains the historical price data
    //we can do this by seeing which one has a timestamp > search date parameter in their maximum/latest round
    if (aggregators.length === 0) {
      throw new Error("no aggreagator found");
    } else {
      //multiple aggregators found
      //we need to loop through and find which one is the one we want to search
      //because some aggreagators run in parallel, we will loop backwards from the most recent one to the ealiest one
      //the correct aggreagator contract is the first one where the data against the first roundId is < the date being searched
      console.log("multiple aggregators found:", aggregators);
      for (let i = aggregators.length - 1; i > -1; i--) {
        //lets get the aggregator version to work out if its an accessControlledAggreagator contract, or an AggregatorFacade contract, or a legacy Aggregator etc
        //cast to aggreagatorfacade even if its not that, we just need to call the version public getter function which is common across all aggregators
        console.log(`aggregator contract: ${aggregators[i]}`);
        var contractExists = await web3.eth.getCode(aggregators[i]);
        if (contractExists === "0x") {
          console.log("contract destroyed, skipping");
          finalArray = [
            {
              aggregator: aggregators[i],
              phaseId: phaseIds[i],
              rounds: 0,
              firstRound: {
                roundId: 0,
                answer: 0,
                startedAt: 0,
                updatedAt: 0,
                answeredInRound: 0,
              },
              lastRound: {
                roundId: 0,
                answer: 0,
                startedAt: 0,
                updatedAt: 0,
                answeredInRound: 0,
              },
            },
            ...finalArray,
          ];
          rounds = [];
          // console.log("final array length: " + finalArray.length);
          // console.log("final array: " + JSON.stringify(finalArray));
          continue;
        }

        var tempAggregator = new web3.eth.Contract(
          accessControlledAggregatorABI,
          aggregators[i]
        );
        var version = await tempAggregator.methods.version().call();
        console.log("version:", version);

        if (+version === 4) {
          //its an OCR aggreagator
          var aggreagatorBeingSearched = new web3.eth.Contract(
            accessControlledOffchainAggregatorABI,
            aggregators[i]
          );
          var aggreagatorBeingSearchedAddress = aggregators[i];
          var filterTopic = NEW_ROUND_TOPIC;
          console.log(
            new Date().toISOString() +
              ": OCR contract found " +
              aggreagatorBeingSearchedAddress
          );
        } else if (+version === 3) {
          //its an flux aggregator
          var aggreagatorBeingSearched = new web3.eth.Contract(
            accessControlledAggregatorABI,
            aggregators[i]
          );
          var aggreagatorBeingSearchedAddress = aggregators[i];
          var filterTopic = NEW_ROUND_TOPIC;
          console.log(
            new Date().toISOString() +
              ": flux contract found " +
              aggreagatorBeingSearchedAddress
          );
        } else if (+version === 2) {
          //its a facade contract, get the underlying aggreagator contract
          let aggregatorFacade = new web3.eth.Contract(
            aggregatorFacadeABI,
            aggregators[i]
          );
          let aggregatorAddress = await aggregatorFacade.methods
            .aggregator()
            .call();
          console.log(
            new Date().toISOString() +
              ": aggregator contract found: " +
              aggregatorAddress
          );
          var aggreagatorBeingSearched = new web3.eth.Contract(
            aggregatorABI,
            aggregatorAddress
          );
          var aggreagatorBeingSearchedAddress = aggregatorAddress;
          var filterTopic = NEW_ROUND_TOPIC_LEGACY_AGGREGATOR;
        } else if (+version === 1) {
          aggreagatorBeingSearchedAddress = aggregators[i]; //only 1 aggregator, this is the one we need to search
          var filterTopic = NEW_ROUND_TOPIC;
          var aggreagatorBeingSearched = new web3.eth.Contract(
            accessControlledAggregatorABI,
            aggreagatorBeingSearchedAddress
          );
        } else {
          throw new Error("unexpected aggregator version found");
        }

        //now that we've validated everything, and found the correct aggreagator contract, lets populated an array of all the roundIds in the aggregator
        console.log(
          new Date().toISOString() +
            ": finding rounds for aggreagator " +
            aggreagatorBeingSearchedAddress
        );

        var priceFeedsLiveBlockNo;
        var logBatchAmount;

        //depending on which network the adapter is running on, set the starting block for when price feeds went live (to limit the getPastLogs query)
        //also we're limiting chunking depending on network, this is to avoid timeouts
        var networkId = await web3.eth.net.getId();
        if (+networkId === 1) {
          //mainnet
          priceFeedsLiveBlockNo = MAINNET_PRICE_FEEDS_LIVE_BLOCK_NO;
          logBatchAmount = MAINNET_LOG_CHUNKS;
        } else {
          throw new Error("network not supported: " + networkId);
        }

        //now we're reading to iterate through all the 'newRound' logs for this aggreagator. This allows us to create an array with all the rounds in the aggregator
        //with 100% certainty that the round numbers are correct
        var startedAt;

        console.log(
          new Date().toISOString() +
            ": searching for logs from blocks beginning at: " +
            MAINNET_PRICE_FEEDS_LIVE_BLOCK_NO +
            " to block " +
            currentBlock
        );
        for (
          let i = parseInt(priceFeedsLiveBlockNo);
          i < currentBlock + 1;
          i += logBatchAmount
        ) {
          let toBlock = parseInt(i) + parseInt(logBatchAmount - 1);
          toBlock = toBlock > currentBlock ? currentBlock : toBlock;
          console.log(
            new Date().toISOString() +
              ": searching logs from blocks " +
              i +
              " to " +
              toBlock
          );

          let res = await web3.eth.getPastLogs({
            address: aggreagatorBeingSearchedAddress,
            topics: [filterTopic],
            fromBlock: parseInt(i),
            toBlock: parseInt(toBlock),
          });
          console.log("response recieved. Version: " + version);
          if (!(Object.keys(res).length === 0)) {
            for (const rec of res) {
              const roundId = new BigNumber(
                web3.eth.abi.decodeParameter("int256", rec.topics[1])
              );
              //if its a flux aggreagator, we can filter out 0 results by looking at the startedAt topic, potentially saving some time later on
              if (+version === 2) {
                startedAt = web3.eth.abi.decodeParameter(
                  "int256",
                  rec.topics[2]
                );
                if (startedAt > 0) {
                  rounds.push(roundId.toString());
                }
              } else {
                //not a flux aggregator, have to add the round and sort it out after if any zero results are found
                rounds.push(roundId.toString());
              }
            }
          }
        }

        // getRoundData from the first round and the last round
        // this is to get the timestamp of the first and last round
        console.log(
          "getting round data for first (round 1) and last round: " +
            rounds[rounds.length - 1]
        );
        let firstRound, lastRound;
        if (rounds && rounds.length > 0) {
          try {
            console.log("searching for first round data...");
            firstRound = await aggreagatorBeingSearched.methods
              .getRoundData(rounds[0])
              .call();
            console.log("first round data retrieved!");
          } catch (e) {
            console.log("error getting first round data: " + e);
          }
          try {
            console.log("searching for last round data...");
            lastRound = await aggreagatorBeingSearched.methods
              .getRoundData(rounds[rounds.length - 1])
              .call();
            console.log("last round data retrieved!");
          } catch (e) {
            console.log("error getting last round data: " + e);
          }
        } else {
          console.log(
            "no rounds found for aggregator " + aggreagatorBeingSearchedAddress
          );
        }

        if (rounds.length === 0) {
          console.log(
            "no logs found for aggregator " + aggreagatorBeingSearchedAddress
          );
        }
        console.log("rounds found: " + rounds.length);
        // console.log("rounds slice: " + rounds);
        // add the rounds to the array of all rounds
        finalArray = [
          {
            aggregator: aggregators[i],
            phaseId: phaseIds[i],
            rounds: rounds.length + 1,
            firstRound: firstRound
              ? {
                  roundId: firstRound.roundId,
                  answer: firstRound.answer,
                  startedAt: firstRound.startedAt,
                  updatedAt: firstRound.updatedAt,
                  answeredInRound: firstRound.answeredInRound,
                }
              : {
                  roundId: 0,
                  answer: 0,
                  startedAt: 0,
                  updatedAt: 0,
                  answeredInRound: 0,
                },
            lastRound: lastRound
              ? {
                  roundId: lastRound.roundId,
                  answer: lastRound.answer,
                  startedAt: lastRound.startedAt,
                  updatedAt: lastRound.updatedAt,
                  answeredInRound: lastRound.answeredInRound,
                }
              : {
                  roundId: 0,
                  answer: 0,
                  startedAt: 0,
                  updatedAt: 0,
                  answeredInRound: 0,
                },
          },
          ...finalArray,
        ];
        rounds = [];
        firstRound = [];
        lastRound = [];
        // console.log("final array length: " + finalArray.length);
        // console.log("final array: " + JSON.stringify(finalArray));
      }
    }
    console.log("Succes! Final array: " + JSON.stringify(finalArray));
    return finalArray;
  } catch (error) {
    return error;
  }
};

// add setTimeout to the function to return sample data
// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           aggregator: "0xAec0D77fdD6a2a34EC3eaF915260496Ae27f9D25",
//           phaseId: 2,
//           rounds: 7614,
//           firstRound: {
//             roundId: "1",
//             answer: "2410224896056",
//             startedAt: "1606244889",
//             updatedAt: "1606248636",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "7612",
//             answer: "3181854419281",
//             startedAt: "1637871260",
//             updatedAt: "1637871369",
//             answeredInRound: "7612",
//           },
//         },
//         {
//           aggregator: "0xe4B36bbC01eAD5A378d4Cb088604BFe5ab2CeFE3",
//           phaseId: 3,
//           rounds: 4759,
//           firstRound: {
//             roundId: "1",
//             answer: "3386892597166",
//             startedAt: "1614249950",
//             updatedAt: "1614249950",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "4757",
//             answer: "3025305058393",
//             startedAt: "1627498909",
//             updatedAt: "1627498909",
//             answeredInRound: "4757",
//           },
//         },
//         {
//           aggregator: "0x8a4D74003870064d41D4f84940550911FBfCcF04",
//           phaseId: 4,
//           rounds: 17394,
//           firstRound: {
//             roundId: "1",
//             answer: "3104787488345",
//             startedAt: "1625210739",
//             updatedAt: "1625210739",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "17392",
//             answer: "517961628000",
//             startedAt: "1672357259",
//             updatedAt: "1672357259",
//             answeredInRound: "17392",
//           },
//         },
//       ]);
//     }, 2000);
//   });
// };

// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           aggregator: "0xF5fff180082d6017036B771bA883025c654BC935",
//           phaseId: 1,
//           rounds: 0,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//         },
//         {
//           aggregator: "0x276De5c241071b4728697F4e11a377484a2Dd6cB",
//           phaseId: 2,
//           rounds: 941,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//         },
//         {
//           aggregator: "0xF570deEffF684D964dc3E15E1F9414283E3f7419",
//           phaseId: 3,
//           rounds: 19066,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: "19065",
//             answer: "4896053970528",
//             startedAt: "1638696026",
//             updatedAt: "1638981588",
//             answeredInRound: "19065",
//           },
//         },
//         {
//           aggregator: "0x7104Ac4abCecF1680F933b04c214B0C491D43ecC",
//           phaseId: 4,
//           rounds: 3709,
//           firstRound: {
//             roundId: "1",
//             answer: "5483818419990",
//             startedAt: "1615454725",
//             updatedAt: "1615454725",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "3708",
//             answer: "3993739000000",
//             startedAt: "1621492711",
//             updatedAt: "1621492711",
//             answeredInRound: "3708",
//           },
//         },
//         {
//           aggregator: "0xAe74faA92cB67A95ebCAB07358bC222e33A34dA7",
//           phaseId: 5,
//           rounds: 30216,
//           firstRound: {
//             roundId: "1",
//             answer: "5656077446879",
//             startedAt: "1620758509",
//             updatedAt: "1620758509",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "30215",
//             answer: "1682764307605",
//             startedAt: "1672872935",
//             updatedAt: "1672872935",
//             answeredInRound: "30215",
//           },
//         },
//       ]);
//     }, 2000);
//   });
// };

// ETH/1INCH -
// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           aggregator: "0x76c82bA3C5962e41a896E54A4fdf9F13dA90EB7f",
//           phaseId: 1,
//           rounds: 975,
//           firstRound: {
//             roundId: "1",
//             answer: "3082150000000000",
//             startedAt: "1612310749",
//             updatedAt: "1612317171",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "974",
//             answer: "757719518756572",
//             startedAt: "1638499983",
//             updatedAt: "1638500023",
//             answeredInRound: "974",
//           },
//         },
//         {
//           aggregator: "0xb2F68c82479928669B0487D1dAeD6Ef47b63411e",
//           phaseId: 2,
//           rounds: 2473,
//           firstRound: {
//             roundId: "1",
//             answer: "2472510000000000",
//             startedAt: "1616551020",
//             updatedAt: "1616551020",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "2472",
//             answer: "309616599213071",
//             startedAt: "1673019227",
//             updatedAt: "1673019227",
//             answeredInRound: "2472",
//           },
//         },
//       ]);
//     }, 2000);
//   });
// };

// BTC/USD
// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           aggregator: "0xF5fff180082d6017036B771bA883025c654BC935",
//           phaseId: 1,
//           rounds: 0,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//         },
//         {
//           aggregator: "0x276De5c241071b4728697F4e11a377484a2Dd6cB",
//           phaseId: 2,
//           rounds: 941,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//         },
//         {
//           aggregator: "0xF570deEffF684D964dc3E15E1F9414283E3f7419",
//           phaseId: 3,
//           rounds: 19066,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: "19065",
//             answer: "4896053970528",
//             startedAt: "1638696026",
//             updatedAt: "1638981588",
//             answeredInRound: "19065",
//           },
//         },
//         {
//           aggregator: "0x7104Ac4abCecF1680F933b04c214B0C491D43ecC",
//           phaseId: 4,
//           rounds: 3709,
//           firstRound: {
//             roundId: "1",
//             answer: "5483818419990",
//             startedAt: "1615454725",
//             updatedAt: "1615454725",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "3708",
//             answer: "3993739000000",
//             startedAt: "1621492711",
//             updatedAt: "1621492711",
//             answeredInRound: "3708",
//           },
//         },
//         {
//           aggregator: "0xAe74faA92cB67A95ebCAB07358bC222e33A34dA7",
//           phaseId: 5,
//           rounds: 30265,
//           firstRound: {
//             roundId: "1",
//             answer: "5656077446879",
//             startedAt: "1620758509",
//             updatedAt: "1620758509",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "30264",
//             answer: "1680811000000",
//             startedAt: "1673027735",
//             updatedAt: "1673027735",
//             answeredInRound: "30264",
//           },
//         },
//       ]);
//     }, 2000);
//   });
// };

// AAPL/USD
// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           aggregator: "0xd2bdD1E01fd2F8d7d42b209c111c7b32158b5a42",
//           phaseId: 1,
//           rounds: 13810,
//           firstRound: {
//             roundId: "1",
//             answer: "448793445",
//             startedAt: "1617349521",
//             updatedAt: "1617349521",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "13809",
//             answer: "39369703",
//             startedAt: "1672860635",
//             updatedAt: "1672860635",
//             answeredInRound: "13809",
//           },
//         },
//       ]);
//     }, 2000);
//   });
// };

// ETH/USD
// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           aggregator: "0xF79D6aFBb6dA890132F9D7c355e3015f15F3406F",
//           phaseId: 1,
//           rounds: 0,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//         },
//         {
//           aggregator: "0xB103ede8AcD6F0c106b7a5772e9D24e34f5Ebc2C",
//           phaseId: 2,
//           rounds: 2600,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//         },
//         {
//           aggregator: "0x00c7A37B03690fb9f41b5C5AF8131735C7275446",
//           phaseId: 3,
//           rounds: 18916,
//           firstRound: {
//             roundId: "1",
//             answer: "39178992539",
//             startedAt: "1596755816",
//             updatedAt: "1596755816",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             answer: "170997568356",
//             answeredInRound: "18916",
//             roundId: 18916,
//             startedAt: "1612971191",
//             updatedAt: "1612971263",
//           },
//         },
//         {
//           aggregator: "0xd3fCD40153E56110e6EEae13E12530e26C9Cb4fd",
//           phaseId: 4,
//           rounds: 5057,
//           firstRound: {
//             roundId: "1",
//             answer: "174739945639",
//             startedAt: "1615454588",
//             updatedAt: "1615454588",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "5056",
//             answer: "265818691336",
//             startedAt: "1621492839",
//             updatedAt: "1621492839",
//             answeredInRound: "5056",
//           },
//         },
//         {
//           aggregator: "0x37bC7498f4FF12C19678ee8fE19d713b87F6a9e6",
//           phaseId: 5,
//           rounds: 39613,
//           firstRound: {
//             roundId: "1",
//             answer: "408110595768",
//             startedAt: "1620758509",
//             updatedAt: "1620758509",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "39612",
//             answer: "126625000000",
//             startedAt: "1673042135",
//             updatedAt: "1673042135",
//             answeredInRound: "39612",
//           },
//         },
//       ]);
//     }, 2000);
//   });
// };

// SNX/USD
// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           aggregator: "0x767395b2652B79fB588b5169B0d9cAe5Fa873cfB",
//           phaseId: 1,
//           rounds: 2491,
//           firstRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//           lastRound: {
//             roundId: 0,
//             answer: 0,
//             startedAt: 0,
//             updatedAt: 0,
//             answeredInRound: 0,
//           },
//         },
//         {
//           aggregator: "0xC8DB8d5869510Bb1FCd3Bd7C7624c1b49c652ef8",
//           phaseId: 2,
//           rounds: 12935,
//           firstRound: {
//             roundId: "1",
//             answer: "503855116",
//             startedAt: "1601285167",
//             updatedAt: "1601285213",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "12934",
//             answer: "653144338",
//             startedAt: "1638593028",
//             updatedAt: "1638981380",
//             answeredInRound: "12934",
//           },
//         },
//         {
//           aggregator: "0x06ce8Be8729B6bA18dD3416E3C223a5d4DB5e755",
//           phaseId: 3,
//           rounds: 18025,
//           firstRound: {
//             roundId: "1",
//             answer: "2365225546",
//             startedAt: "1614767180",
//             updatedAt: "1614767180",
//             answeredInRound: "1",
//           },
//           lastRound: {
//             roundId: "18024",
//             answer: "162904920",
//             startedAt: "1673046839",
//             updatedAt: "1673046839",
//             answeredInRound: "18024",
//           },
//         },
//       ]);
//     }, 2000);
//   });
// };

// SNX/USD
// const evaluateOracle = async (aggregatorProxyAddress) => {
//   console.log("Simulating evaluating oracle: " + aggregatorProxyAddress);
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([{"aggregator":"0xa7D38FBD325a6467894A13EeFD977aFE558bC1f0","phaseId":1,"rounds":0,"firstRound":{"roundId":0,"answer":0,"startedAt":0,"updatedAt":0,"answeredInRound":0},"lastRound":{"roundId":0,"answer":0,"startedAt":0,"updatedAt":0,"answeredInRound":0}},{"aggregator":"0xcd0465b4ba06f3193ADd14272af187aB414534ff","phaseId":2,"rounds":260,"firstRound":{"roundId":0,"answer":0,"startedAt":0,"updatedAt":0,"answeredInRound":0},"lastRound":{"roundId":0,"answer":0,"startedAt":0,"updatedAt":0,"answeredInRound":0}},{"aggregator":"0xFe16F630Eb0Ca70661B071360701abf980126d3e","phaseId":3,"rounds":439,"firstRound":{"roundId":"1","answer":"100807100","startedAt":"1596787587","updatedAt":"1598004985","answeredInRound":"1"},"lastRound":{"roundId":"438","answer":"99980000","startedAt":"1638593289","updatedAt":"1638593304","answeredInRound":"438"}},{"aggregator":"0x4588EC4ddcF1D8dbcb5a1273d22F8485885C45A4","phaseId":4,"rounds":93,"firstRound":{"roundId":"1","answer":"100142684","startedAt":"1614252753","updatedAt":"1614252753","answeredInRound":"1"},"lastRound":{"roundId":"92","answer":"100320985","startedAt":"1621429360","updatedAt":"1621429360","answeredInRound":"92"}},{"aggregator":"0xDEc0a100eaD1fAa37407f0Edc76033426CF90b82","phaseId":5,"rounds":14738,"firstRound":{"roundId":"1","answer":"100143366","startedAt":"1620786989","updatedAt":"1620786989","answeredInRound":"1"},"lastRound":{"roundId":"14737","answer":"100001056","startedAt":"1673046455","updatedAt":"1673046455","answeredInRound":"14737"}}]);
//     }, 2000);
//   });
// };

export { evaluateOracle };
