import { useAxios } from "../useAxios";
import { get, ref as ref_rtdb } from "firebase/database";
import { useEffect, useState } from "react";
import { rtdb } from "../../firebase/firebase.config";
import { Download, Link, Settings, LoadingWheel } from "../Icons";
import { truncateEthAddress } from "../ethers/truncateEthAddress";
import { Selector } from "../demo/selector";
import { JSONSnippet } from "../demo/JSONSnippet";
import { ClosestRoundAfter } from "../demo/closestRoundAfter";
import { ClosestRoundPrior } from "../demo/closestRoundPrior";
import { SimpleMovingAverage } from "../demo/simpleMovingAverage";
import { TimeUnitPanel } from "../demo/timeUnitPanel";
import { OHLChart } from "../demo/OHLChart";
import { UpdateHistoryChart } from "../demo/UpdateHistoryChart";

const Demo = ({ data }) => {
  // setup react state variables
  const [selected, setSelected] = useState(null);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState(30);
  const [key, setKey] = useState(null); // used to force a re-render. Hacky.
  const [dateRange, setDateRange] = useState(null);
  const [updateCount, setUpdateCount] = useState(0);
  const [proxyAddress, setProxyAddress] = useState(null);
  const [deviationThreshold, setDeviationThreshold] = useState(null);
  const [heartbeat, setHeartbeat] = useState(1);
  const [, setNetwork] = useState(null); // for future update
  const [chartIsPending, setChartIsPending] = useState(true);

  // unpack data
  const {
    chain,
    pair,
    chartUrl,
    jsonFileUrl,
    csvFileUrl,
    jsonFileMetadata,
    csvFileMetadata,
  } = data;

  // Build RTDB References
  const rtdbRef = ref_rtdb(rtdb, `oracles/${chain}_${pair}`);
  const dateRef = ref_rtdb(rtdb, `data/${chain}_${pair}`);

  // Get data for chart
  const { isPending, data: chartData } = useAxios(chartUrl);

  const selectorChange = (f) => {
    setSelected(f.value);
    setKey((prev) => prev + 1);
  };
  const timeChange = (f) => {
    setSelectedTimeUnit(f);
    setKey((prev) => prev + 1);
  };
  // Set data set from RTDB
  useEffect(() => {
    if (chartData) {
      const updateCount = chartData.map((chartData) => chartData.updateCount);
      const totalUpdates = updateCount.reduce((a, b) => a + b);
      setUpdateCount(
        totalUpdates.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
      setDateRange([chartData[0].date, chartData[chartData.length - 1].date]); // YYYY-MM-DDThh:mm
      selectorChange({ value: "priceHistoryChart" });
      setChartIsPending(false);
    }
  }, [chartData, isPending]);

  useEffect(() => {
    // get the data from the RTDB
    get(rtdbRef).then((snapshot) => {
      if (snapshot.exists()) {
        setProxyAddress(snapshot.val().proxyAddress);
        setDeviationThreshold(snapshot.val().deviationThreshold);
        setHeartbeat(snapshot.val().heartbeat);
        setNetwork(snapshot.val().chain);
      } else {
        console.log("No rtdb data available");
      }
    });
  }, [rtdbRef]);

  return (
    <section className="text-black py-20 body-font w-full bg-background-light flex grow items-center justify-center  ">
      {/* Translucent box thing with rounded corners */}
      <div className="container pt-28 flex rounded-xl border border-foreground-alt-500 shadow pb-4 sm:p-8 px-2  mx-1 ">
        <div className="flex flex-wrap h-full w-full justify-center align-middle items-center">
          {/* Div for Pair Name, Description, and Information */}
          <div className="lg:w-1/3 md:w-1/3 md:px-4 md:py-6 ">
            {/* Pair Name and Loading Status */}
            <div className="flex relative pb-12">
              <h1 className="pl-4 font-semibold text-5xl title-font text-gray-900 ">
                {pair}
              </h1>
            </div>
            {/* Pair Description ... needs to be pulled up after reading demo data */}
            <div className="flex relative pb-12">
              <div className="w-10 absolute inset-0 flex items-center justify-center">
                <div className="w-1 h-full bg-gray-800 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-emerald-600 inline-flex items-center justify-center text-black relative z-10">
                <Settings />
              </div>
              <div className="flex-grow pl-4">
                <h2 className=" font-semibold text-xl title-font text-gray-900">
                  Update Parameters
                </h2>
                <ul className="leading-relaxed">
                  <li>Deviation Threshold: {deviationThreshold}%</li>
                  <li>
                    Heartbeat: {heartbeat} {heartbeat <= 1 ? "hr" : "hrs"}
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex relative pb-12 ">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-800 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-emerald-600 inline-flex items-center justify-center text-black relative z-10">
                <Link />
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-semibold text-xl title-font text-gray-900">
                  Oracle Information
                </h2>
                <p>
                  Start Date: {chartData ? chartData[0].date : "loading..."}
                </p>
                <p>Updates to Blockchain: {updateCount} </p>
                <p className="leading-relaxed">
                  Proxy Contract Address:
                  <a
                    href={`https://${
                      chain === "GOERLI" ? "goerli." : ""
                    }etherscan.io/address/${proxyAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold hover:text-emerald-600 focus:text-emerald-600 hover:font-bold"
                  >
                    {" "}
                    {truncateEthAddress(proxyAddress)}
                  </a>
                </p>
                <p>
                  Network:{" "}
                  {chain === "GOERLI" ? "Goerli Testnet" : "Ethereum Mainnet"}
                </p>
              </div>
            </div>

            <div className="flex relative pb-4">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-emerald-600 inline-flex items-center justify-center text-black relative z-10">
                <Download />
              </div>
              <div className="flex-grow pl-4 ">
                <h2 className="font-semibold text-xl title-font text-gray-900">
                  Download Complete Dataset
                </h2>
                <ul className="leading-relaxed">
                  <li>(Updated every two hours)</li>
                </ul>

                <a
                  href={jsonFileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row items-center "
                >
                  <p className="font-semibold hover:text-emerald-600 hover:font-bold focus:text-emerald-600 mr-1">
                    JSON file
                  </p>
                  <p className="text-xs mt-1">({jsonFileMetadata})</p>
                </a>

                <a
                  href={csvFileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row items-center "
                >
                  <p className="font-semibold  hover:text-emerald-600 hover:font-bold focus:text-emerald-600 mr-1 ">
                    CSV file
                  </p>
                  <p className="text-xs mt-1 "> ({csvFileMetadata})</p>
                </a>
              </div>
            </div>
          </div>
          {chartIsPending ? (
            <div className="lg:w-2/3 md:w-5/6 md:pr-2 md:py-2 rounded-lg md:px-4 flex-col md:mt-0 mt-12 w-full inline-flex items-center justify-center">
              <p>Data is loading ... </p>
              <br />
              <LoadingWheel />
            </div>
          ) : (
            <div className="lg:w-2/3 md:w-5/6 md:pr-2 md:py-2 rounded-lg md:px-4 flex-col md:mt-0 mt-12 w-full justify-center object-cover">
              <div className="flex justify-between items-end">
                <Selector selectorChange={selectorChange} />
                {(selected === "priceHistoryChart" ||
                  selected === "updateHistoryChart") && (
                  <TimeUnitPanel timeChange={timeChange} />
                )}
              </div>
              <div className="flex justify-center items-end">
                {selected === "priceHistoryChart" && (
                  <OHLChart
                    data={chartData}
                    description={pair}
                    // rangeChange={rangeChange}
                    margin={{ top: 10, right: 10, left: 5, bottom: 10 }}
                    key={key}
                    selectedTime={selectedTimeUnit}
                  />
                )}
                {selected === "updateHistoryChart" && (
                  <UpdateHistoryChart
                    data={chartData}
                    description={pair}
                    margin={{ top: 10, right: 10, left: 5, bottom: 10 }}
                    key={key}
                    selectedTime={selectedTimeUnit}
                  />
                )}
                {selected === "database" && <JSONSnippet />}
                {selected === "ClosestRoundAfter" && (
                  <ClosestRoundAfter range={dateRange} dateRef={dateRef} />
                )}
                {selected === "ClosestRoundPrior" && (
                  <ClosestRoundPrior range={dateRange} dateRef={dateRef} />
                )}
                {selected === "SimpleMovingAverage" && (
                  <SimpleMovingAverage range={dateRange} dateRef={dateRef} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Demo;
