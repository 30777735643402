import { getDefaultProvider, Contract } from "ethers";
import { EACAggregatorProxyABI } from "./config";
// get contract proxy address from rtdb
const connectProviderContract = async (
  contractAddress,
  chain,
  ABI = EACAggregatorProxyABI
) => {
  const networks = {
    ETH: "homestead",
    ETHEREUM: "homestead",
    RINKEBY: "rinkeby",
    KOVAN: "kovan",
    GOERLI: "goerli",
    SOL: "solana",
    MATIC: "matic",
    BSC: "bsc",
    FTM: "ftm",
    AVALANCHE: "avalanche",
    AVAX: "avalanche",
  };
  const network = networks[chain];
  if (!network) {
    throw new Error("Invalid network");
  }
  if (!contractAddress) {
    throw new Error("Invalid contract address");
  }
  let provider;
  if (window.location.hostname === "localhost") {
    console.log("localhost detected");
    provider = getDefaultProvider(network, {
      infura: `${process.env.REACT_APP_INFURA_TOKEN}`,
      // ankr: "https://rpc.ankr.com/eth",
      alchemy: `${process.env.REACT_APP_ALCHEMY_TOKEN}`,
      // cloudflare: "https://cloudflare-eth.com",
      // etherscan: `${process.env.REACT_APP_ETHERSCAN_TOKEN}`,
    });
  } else {
    provider = getDefaultProvider(network, {
      infura: "20xx3MrHM079JSJYHdeeuAGeZp4",
    });
  }

  // const provider = getDefaultProvider(network, {
  //   ankr: "https://rpc.ankr.com/eth",
  // });

  // const provider = getDefaultProvider(network, {
  //   alchemy: `${process.env.REACT_APP_ALCHEMY_TOKEN}`,
  // });
  const contract = new Contract(
    contractAddress,
    ABI, //aggregatorV3InterfaceABI,
    provider
  );

  return contract;
};

export { connectProviderContract };
