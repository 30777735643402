export const getServerSideChainMetadata = async (chains) => {
  const cache = {};
  chains.forEach(async (chain) => {
    const requests = chain.networks.map((nw) =>
      nw.rddUrl
        ? fetch(nw.rddUrl)
            .then((res) => res.json())
            .then((metadata) => ({
              ...nw,
              metadata: metadata.filter((meta) => meta.docs?.hidden !== true),
            }))
        : undefined
    );
    const networks = await Promise.all(requests);
    cache[chain.page] = { ...chain, networks };
  });
  // console.log("cache", cache);
  return cache;
};
