const snippet = [
  {
    answer: 635791825,
    answeredInRound: "18446744073709551617",
    price: 6.36,
    roundId: "18446744073709551617",
    startedAt: 1658943647,
    updatedAt: 1658943647,
  },
  {
    answer: 636058493,
    answeredInRound: "18446744073709551618",
    price: 6.36,
    roundId: "18446744073709551618",
    startedAt: 1658943888,
    updatedAt: 1658943888,
  },
];

const JSONSnippet = () => {
  return (
    <div className="flex flex-auto p-2 text-gray-300 bg-gray-800 rounded-lg mt-6 overflow-auto text-xs max-w-sm sm:max-w-lg md:max-w-full  md:text-base lg:w-full ">
      <pre className="flex flex-col ">
        <code className="flex ">{JSON.stringify(snippet, null, 2)}</code>
        <br />
        <code className="flex ">Note: 'price' is a calculated value</code>
      </pre>
    </div>
  );
};

export { JSONSnippet };
