import { Navbar } from "./components/Navbar";
import Landing from "./components/Pages/Landing";
import About from "./components/Pages/About";
import Introduction from "./components/Pages/Introduction";
import Footer from "./components/Footer";
import Demo from "./components/Pages/Demo";
import Lookup from "./components/Pages/Lookup";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ref, getDownloadURL, getMetadata } from "firebase/storage";
import { storage } from "./firebase/firebase.config";
import { useEffect, useState } from "react";
import prettyBytes from "pretty-bytes";
import { LoadingWheel } from "./components/Icons";
import { CHAINS, SOLANA_CHAINS, ALL_CHAINS } from "./feeds/chainListing";
import { getServerSideChainMetadata } from "./feeds/backEnd";

function App() {
  const [data, setData] = useState({ chain: null, pair: null });
  const [loading, setLoading] = useState(true);
  const [cache, setCache] = useState(null);

  const handleClick = async (chain, pair) => {
    const getUrl = async (chain, pair) => {
      // Build Storage References
      const pairRef = ref(storage);
      const chartRef = ref(pairRef, `ohlChart/${chain}_${pair}.json`);
      const jsonFileRef = ref(pairRef, `data/${chain}_${pair}.json`);
      const csvFileRef = ref(pairRef, `csv/${chain}_${pair}.csv`);

      // Get Download URLs

      const jsonFileUrl = await getDownloadURL(jsonFileRef);
      const csvFileUrl = await getDownloadURL(csvFileRef);
      const chartUrl = await getDownloadURL(chartRef);

      // Get File Metadata
      let jsonFileMetadata = await getMetadata(jsonFileRef);
      let csvFileMetadata = await getMetadata(csvFileRef);

      // Format File Metadata
      jsonFileMetadata = prettyBytes(jsonFileMetadata.size);
      csvFileMetadata = prettyBytes(csvFileMetadata.size);

      return {
        chartUrl,
        jsonFileUrl,
        csvFileUrl,
        jsonFileMetadata,
        csvFileMetadata,
      };
    };
    const getCache = async () => {
      const tempCache = await getServerSideChainMetadata([
        ...CHAINS,
        ...SOLANA_CHAINS,
        ...ALL_CHAINS,
      ]);
      setCache(tempCache);
    };
    if (chain === null && pair === null && cache === null) {
      setLoading(true);
      await getCache().then(() => {
        setLoading(false);
        return;
      });
    } else if (chain === null && pair === null && cache !== null) {
      setLoading(false);
      return;
    } else {
      await getUrl(chain, pair).then((response) => {
        setData({ chain: chain, pair: pair, ...response });
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const windowUrl = window.location.pathname;
    if (
      windowUrl === "/" ||
      windowUrl === "/about" ||
      windowUrl === "/introduction" ||
      windowUrl === "/lookup"
    ) {
      const chain = null;
      const pair = null;
      handleClick(chain, pair);
      return;
    } else {
      const chain = windowUrl.split("/")[1];
      const pair = windowUrl.split("/")[2];
      handleClick(chain, pair);
    }
  });

  return (
    <Router>
      <div className="Navbar">
        <Navbar pathChange={handleClick} />
      </div>
      <div className="Content   ">
        <div className="App bg-gradient-to-r from-gray-200 to-emerald-50 min-h-screen flex items-center justify-center ">
          {loading ? (
            <div>
              <p>Data is loading ... </p>
              <br />
              <LoadingWheel />
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Landing />}></Route>
              <Route exact path="/about" element={<About />}></Route>
              <Route
                exact
                path="/lookup"
                element={<Lookup cache={cache} />}
              ></Route>
              <Route
                exact
                path="/introduction"
                element={<Introduction />}
              ></Route>
              <Route
                exact
                path="/GOERLI/LINKUSD"
                element={<Demo data={data} />}
              ></Route>
              <Route
                exact
                path="/ETH/ETHUSD"
                element={<Demo data={data} />}
              ></Route>
              <Route
                exact
                path="/ETH/BTCUSD"
                element={<Demo data={data} />}
              ></Route>
            </Routes>
          )}
        </div>

        <div className="Footer sticky top-[100vh]">
          <Footer />
        </div>
      </div>
    </Router>
  );
}
export default App;
