// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrj-XcU75Z9wcQBTHCmDnDa9SsEuZErTk",
  authDomain: "link-f5e9b.firebaseapp.com",
  projectId: "link-f5e9b",
  storageBucket: "link-f5e9b.appspot.com",
  messagingSenderId: "365956293679",
  appId: "1:365956293679:web:b3b56edc518f105ba512da",
};

const app = initializeApp(firebaseConfig);
let storage = null;
let db = null;
let rtdb = null;

if (window.location.hostname === "localhost") {
  storage = getStorage();
  connectStorageEmulator(storage, "localhost", 9199);
  db = getFirestore();
  connectFirestoreEmulator(db, "localhost", 8080);
  console.log(
    "Connected to Firebase Firestore Emulator Project: ",
    db.app.options.projectId
  );
  rtdb = getDatabase();
  connectDatabaseEmulator(rtdb, "localhost", 9000);
  console.log(
    "Connected to Firebase Realtime Database Emulator Project: ",
    rtdb.app.options.projectId
  );
} else {
  storage = getStorage(app);
  db = getFirestore(app);
  rtdb = getDatabase(app);
}
export { storage, app, db, rtdb };
