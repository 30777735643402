import Select from "react-select";

const SubSelector = ({ blockChainChange, data }) => {
  // console.log("data", data);
  return (
    <Select
      styles={{
        input: (base) => ({
          ...base,
          color: "rgb(var(--color-foreground-alt-200))",
        }),
        control: (base) => ({
          ...base,
          maxWidth: 350,
          marginLeft: 5,
          backgroundColor: "rgb(var(--color-background))",
          borderWidth: "1px",
          borderColor: "rgb(var(--color-foreground-alt-200))",
          color: "rgb(var(--color-foreground-alt-200))",
        }),
        menu: (provided) => ({
          ...provided,
          //   top: 40,
          maxWidth: 400,
          marginLeft: 5,
          borderRadius: 10,
          borderWidth: "2px",
          borderColor: "rgb(var(--color-foreground-alt-400))",
          //   backgroundColor: "rgb(var(--color-background))",
          color: "rgb(var(--color-foreground-alt-200))",
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          text: "rgb(var(--color-foreground-alt-200))",
          primary: "rgb(var(--color-foreground-alt-300))",
          primary25: "rgb(var(--color-foreground-alt-400))",
          primary50: "black",
          primary75: "black",
          neutral10: "black",
          neutral80: "rgb(var(--color-foreground-alt-200))",
        },
      })}
      defaultValue={{
        value: "Select Mainnet or Testnet",
        label: "Select Mainnet or Testnet",
      }}
      onChange={(value) => blockChainChange(value)}
      options={[{ value: "mainnet", label: "Ethereum Mainnet" }]} // TODO change back to data
      className="flex ml-4 mb-2 justify-center lg:justify-end text-gray-600"
      isSearchable={false}
    />
  );
};

export { SubSelector };
